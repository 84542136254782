import { MapTo, withComponentMappingContext } from '../ComponentMapping';
import { AllowedComponentsContainer } from './allowedcomponents/AllowedComponentsContainer';
const PLACEHOLDER_CLASS_NAMES = 'aem-Grid-newComponent';
export class ResponsiveGrid extends AllowedComponentsContainer {
    /**
     * The attributes that will be injected in the root element of the container.
     *
     * @returns {Object} - the attributes of the container
     */
    get containerProps() {
        const containerProps = super.containerProps;
        containerProps.className = (containerProps.className || '') + ' ' + this.props.gridClassNames;
        return containerProps;
    }
    /**
     * The properties that will go on the placeholder component root element
     *
     * @returns {Object} - the properties as a map
     */
    get placeholderProps() {
        const props = super.placeholderProps;
        props.placeholderClassNames = (props.placeholderClassNames || '') + ' ' + PLACEHOLDER_CLASS_NAMES;
        return props;
    }
    /**
     * Returns the properties to add on a specific child component
     *
     * @param   {Object} item     The item data
     * @param   {String} itemKey  The key of the item
     * @param   {String} itemPath The path of the item
     * @returns {Object} The map of properties to be added
     */
    getItemComponentProps(item, itemKey, itemPath) {
        const attrs = super.getItemComponentProps(item, itemKey, itemPath);
        attrs.className = attrs.className || '';
        attrs.className += this.props.columnClassNames && this.props.columnClassNames[itemKey] ? ' ' + this.props.columnClassNames[itemKey] : '';
        return attrs;
    }
}
ResponsiveGrid.defaultProps = {
    // Temporary property until CQ-4265892 is addressed, beware not rely it
    _allowedComponentPlaceholderListEmptyLabel: 'No allowed components for Layout Container',
    cqItems: {},
    cqItemsOrder: [],
    cqPath: '',
    title: 'Layout Container'
};
const config = {
    isEmpty(props) {
        return props.cqItemsOrder && props.cqItemsOrder.length > 0;
    }
};
MapTo('wcm/foundation/components/responsivegrid')(withComponentMappingContext(ResponsiveGrid), config);
