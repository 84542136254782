/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import React, { Component } from 'react';
import isEqual from 'react-fast-compare';
import { Constants } from '../Constants';
/**
 * Class name used to identify the placeholder used to represent an empty component.
 *
 * @private
 */
const PLACEHOLDER_CLASS_NAME = 'cq-placeholder';
/**
 * The EditableComponent extends components with editing capabilities.
 */
class EditableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = this.propsToState(props);
    }
    propsToState(props) {
        // Keep private properties from being passed as state
        const { wrappedComponent, containerProps, editConfig, ...state } = props;
        return state;
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps, this.props)) {
            this.setState(this.propsToState(this.props));
        }
    }
    /**
     * Properties related to the edition of the component.
     */
    get editProps() {
        const eProps = {};
        if (!this.props.componentProperties.isInEditor) {
            return eProps;
        }
        eProps[Constants.DATA_PATH_ATTR] = this.props.componentProperties.cqPath;
        return eProps;
    }
    /**
     * HTMLElement representing the empty placeholder.
     *
     * @return {object}
     */
    get emptyPlaceholderProps() {
        if (!this.useEmptyPlaceholder()) {
            return null;
        }
        return {
            'className': PLACEHOLDER_CLASS_NAME,
            'data-emptytext': this.props.editConfig.emptyLabel
        };
    }
    /**
     * Should an empty placeholder be added.
     *
     * @return {boolean}
     */
    useEmptyPlaceholder() {
        return this.props.componentProperties.isInEditor
            && (typeof this.props.editConfig.isEmpty === 'function')
            && this.props.editConfig.isEmpty(this.props.componentProperties);
    }
    render() {
        const WrappedComponent = this.props.wrappedComponent;
        return (React.createElement("div", Object.assign({}, this.editProps, this.props.containerProps),
            React.createElement(WrappedComponent, Object.assign({}, this.state)),
            React.createElement("div", Object.assign({}, this.emptyPlaceholderProps))));
    }
}
/**
 * Returns a composition that provides edition capabilities to the component.
 *
 * @param {React.Component} WrappedComponent
 * @param {EditConfig} [editConfig]
 */
export function withEditable(WrappedComponent, editConfig) {
    /**
     * If not edit configuration is specified, provide a dummy that always returns false on isEmpty.
     */
    const editConfigToUse = editConfig ? editConfig : {
        isEmpty(props) {
            return false;
        }
    };
    /**
     * Wrapping Editable Component
     */
    return class CompositeEditableComponent extends Component {
        render() {
            const computedProps = {
                ...this.props,
                componentProperties: this.props,
                editConfig: editConfigToUse,
                wrappedComponent: WrappedComponent
            };
            return React.createElement(EditableComponent, Object.assign({}, computedProps));
        }
    };
}
export { PLACEHOLDER_CLASS_NAME };
