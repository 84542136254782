/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import React from 'react';
import { Container } from '../Container';
import { AllowedComponentPlaceholderList } from './AllowedComponentsPlaceholderList';
/**
 *  When applicable, the component exposes a list of allowed components
 *  This is used by the template editor
 */
export class AllowedComponentsContainer extends Container {
    render() {
        const { allowedComponents, _allowedComponentPlaceholderListEmptyLabel, title, isInEditor } = this.props;
        if (isInEditor && allowedComponents && allowedComponents.applicable) {
            // @ts-ignore
            const emptyLabel = _allowedComponentPlaceholderListEmptyLabel ? _allowedComponentPlaceholderListEmptyLabel : AllowedComponentsContainer.defaultProps._allowedComponentPlaceholderListEmptyLabel;
            if (_allowedComponentPlaceholderListEmptyLabel) {
                // @ts-ignore
                return React.createElement(AllowedComponentPlaceholderList, { title: title, emptyLabel: emptyLabel, components: allowedComponents.components, placeholderProps: this.placeholderProps, cqPath: this.props.cqPath });
            }
        }
        return super.render();
    }
}
AllowedComponentsContainer.defaultProps = {
    // Temporary property until CQ-4265892 is addressed, beware not rely it
    _allowedComponentPlaceholderListEmptyLabel: 'No allowed components',
    cqItems: {},
    cqItemsOrder: [],
    cqPath: ''
};
