/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { normalize as normalizePath } from 'path';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import React, { Component } from 'react';
import isEqual from 'react-fast-compare';
import Utils from '../Utils';
/**
 * Wraps a portion of the page model into a Component.
 * Fetches content from AEM (using ModelManager) and inject it into the passed React Component.
 */
export class ModelProvider extends Component {
    constructor(props) {
        super(props);
        this.getCQPath = this.getCQPath.bind(this);
        this.updateData = this.updateData.bind(this);
        this.state = this.propsToState(props);
    }
    propsToState(props) {
        // Keep private properties from being passed as state
        const { wrappedComponent, cqForceReload, injectPropsOnInit, ...state } = props;
        return state;
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps, this.props)) {
            this.setState(this.propsToState(this.props));
        }
    }
    updateData(cqPath) {
        const path = cqPath || this.props.cqPath;
        ModelManager.getData({ path, forceReload: this.props.cqForceReload }).then((data) => {
            this.setState(Utils.modelToProps(data));
        });
    }
    getCQPath() {
        const { pagePath = '', itemPath = '', injectPropsOnInit } = this.props;
        let { cqPath } = this.props;
        if (injectPropsOnInit && !cqPath) {
            cqPath = (itemPath ?
                `${pagePath}/jcr:content/${itemPath}` :
                pagePath);
            // Normalize path (replace multiple consecutive slashes with a single one).
            cqPath = normalizePath(cqPath);
            this.setState({ cqPath });
        }
        return cqPath;
    }
    componentDidMount() {
        const cqPath = this.getCQPath();
        if (this.props.injectPropsOnInit) {
            this.updateData(cqPath);
        }
        ModelManager.addListener(cqPath, this.updateData);
    }
    componentWillUnmount() {
        // Clean up listener
        ModelManager.removeListener(this.props.cqPath, this.updateData);
    }
    render() {
        const WrappedComponent = this.props.wrappedComponent;
        return React.createElement(WrappedComponent, Object.assign({}, this.state));
    }
}
/**
 * @param WrappedComponent
 * @param {ReloadableModelProperties} [modelConfig]
 */
export const withModel = (WrappedComponent, modelConfig) => {
    /**
     * @type CompositeModelProvider
     */
    return class CompositeModelProviderImpl extends Component {
        render() {
            const modelConfigToUse = modelConfig || {};
            // The reload can be forced either via the withModel function property or locally via the tag's property
            const forceReload = this.props.cqForceReload || modelConfigToUse.forceReload || false;
            const injectPropsOnInit = modelConfigToUse.injectPropsOnInit || false;
            return (React.createElement(ModelProvider, Object.assign({}, this.props, { cqForceReload: forceReload, injectPropsOnInit: injectPropsOnInit, wrappedComponent: WrappedComponent })));
        }
    };
};
