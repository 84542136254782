/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import React from 'react';
import { ComponentMapping } from '@adobe/aem-spa-component-mapping';
import { withEditable } from './components/EditableComponent';
import { withModel } from './components/ModelProvider';
import { withEditorContext } from './EditorContext';
/**
 * Wrapped function
 *
 * @type {function}
 * @private
 */
const wrappedMapFct = ComponentMapping.map;
const wrappedGetFct = ComponentMapping.get;
/**
 * Map a React component with the given resource types.
 * If an {@link EditConfig} is provided the <i>clazz</i> is wrapped to provide edition capabilities on the AEM Page Editor
 *
 * @param {string[]} resourceTypes - list of resource types for which to use the given <i>clazz</i>
 * @param {React.Component} component - class to be instantiated for the given resource types
 * @param {EditConfig} [editConfig] - configuration object for enabling the edition capabilities
 * @param {{}} [config] - general configuration object
 * @param {boolean} [config.forceReload=undefined] - should the model cache be ignored when processing the component
 * @returns {React.Component} - the resulting decorated Class
 */
ComponentMapping.map =
    function map(resourceTypes, component, editConfig, config) {
        const configToUse = config ? config : { forceReload: false };
        let innerComponent = component;
        innerComponent = withEditorContext(withModel(withEditable(innerComponent, editConfig), configToUse));
        wrappedMapFct.call(ComponentMapping, resourceTypes, innerComponent);
        return innerComponent;
    };
ComponentMapping.get = wrappedGetFct;
const MapTo = (resourceTypes) => {
    return (clazz, config) => {
        // @ts-ignore
        return ComponentMapping.map(resourceTypes, clazz, config);
    };
};
const ComponentMappingContext = React.createContext(ComponentMapping);
function withComponentMappingContext(Component) {
    return function ComponentMappingContextComponent(props) {
        return (React.createElement(ComponentMappingContext.Consumer, null, (componentMapping) => React.createElement(Component, Object.assign({}, props, { componentMapping: componentMapping }))));
    };
}
export { ComponentMapping, MapTo, ComponentMappingContext, withComponentMappingContext };
